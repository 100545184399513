<template>
  <div >
    <CreateLocationModal
        :location="selected_location"
        :name_loc="selected_location_name"
        v-on:close-create-location-modal-success="closeCreateLocationModal(true)"
        ref="create_location_modal"></CreateLocationModal>
    <div class="destinations py-5">
      <div class="title fw-b">
        Destinations
      </div>
      <div class="actions flex py-5 justify-between items-center gap-10p">
        <div :disabled="points && points.length > 50" class="add-point white-green w-full flex items-center justify-around gap-10p pointer"
             :class="{'disabled': points.length > 50}"
             @click="addPoint()">
          <div class="icon">
            <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M9.5 1V16.9996" stroke="#1FBC2F" stroke-width="2" stroke-linecap="round"/>
              <path d="M17.5 9L1.50038 9" stroke="#1FBC2F" stroke-width="2" stroke-linecap="round"/>
            </svg>
          </div>
          <span>
                Add drop off
              </span>
        </div>
        <div class="white-green w-full flex items-center justify-around gap-10p"
             @click="reversePoints">
          <div class="icon">
            <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M23.5 4L23.5 10L17.5 10" stroke="#1FBC2F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M23.5 10L18.86 5.64C17.7853 4.56471 16.4556 3.7792 14.9952 3.35677C13.5348 2.93434 11.9911 2.88875 10.5083 3.22426C9.02547 3.55976 7.6518 4.26543 6.51547 5.27542C5.37913 6.2854 4.51717 7.56678 4.01 9" stroke="#1FBC2F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M20.9092 15.2066C20.2028 17.059 18.9046 18.6264 17.2161 19.6653C15.5275 20.7042 13.5432 21.1565 11.5713 20.952C9.59931 20.7474 7.75007 19.8975 6.31072 18.5341C4.87137 17.1708 3.92245 15.3704 3.61132 13.4124C3.30019 11.4544 3.64427 9.44852 4.59012 7.70616C5.53597 5.9638 7.03066 4.58251 8.84208 3.77678C10.6535 2.97106 12.6803 2.78598 14.6077 3.25031C15.3465 3.4283 16.0549 3.6979 16.7178 4.0496" stroke="#1FBC2F" stroke-width="2" stroke-linecap="round"/>
            </svg>
          </div>
          <span >
                Back to origin
              </span>
        </div>
      </div>
    </div>
    <div class="input-group plan_item origin flex flex-col fw-b"  v-for="(point, index) in points" :key="index">
      <div class="relative" v-if="index == 0">
        <label>Start Location</label>
        <div class="outer relative">
          <input autocomplete="off" type="text" class="w-full" :id="'point' + index"
                 v-model="point.point.address"
                 @input="searchAllPoints(index)">
          <div class="input_actions flex justify-around items-center">
            <div class="down pointer w-1/3" @click="points[0].origin_locations_open = !points[0].origin_locations_open">
              <svg :class="{
                        'rotate-180': points[0].origin_locations_open
                      }" width="8" height="5" viewBox="0 0 8 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M7 1L4 4L1 1" stroke="#1FBC2F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </div>
            <div class="flag pointer w-1/3" @click="saveLocation(point, 'Origin')">
              <svg width="10" height="13" viewBox="0 0 10 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 2C1 1.44772 1.44772 1 2 1H8C8.55228 1 9 1.44772 9 2V10.3698C9 11.2893 7.86348 11.7214 7.25259 11.0342L5.74741 9.34084C5.34962 8.89332 4.65038 8.89332 4.25259 9.34084L2.74741 11.0342C2.13652 11.7214 1 11.2893 1 10.3698V2Z" stroke="#1FBC2F" stroke-width="2"/>
              </svg>
            </div>
            <div class="delete w-1/3 no_visibility">
              <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 1L7 7" stroke="#F2374D" stroke-width="2" stroke-linecap="round"/>
                <path d="M7 1L1 7" stroke="#F2374D" stroke-width="2" stroke-linecap="round"/>
              </svg>
            </div>
          </div>
          <div class="locations absolute p-2 bordered" v-if="points[0].origin_locations_open">
            <ul class="flex flex-col" v-for="(location, index) in locations" :key="index">
              <li class="pointer py-2" @click="selectLocation(location, 0)">
                <span class='location-name'>{{location.name}}</span> - {{location.address}}
              </li>
            </ul>
          </div>
        </div>
        <div class="dropdown_block" v-if="point.showList">
          <ul class="search-dropdown flex flex-col gap-5 py-5">
            <li v-for="(option, i) in point.points" :key="i">
              <a href="javascript:void(0)" @click="updateDropOption(option, index)">
                {{ option.address }}
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div class="relative" v-else>
        <label >{{index}} drop</label>
        <div class="outer relative">
          <input autocomplete="off" type="text" class="w-full" :id="'point' + index"
                 v-model="point.point.address" @input="searchAllPoints(index)"
          >
          <div class="input_actions flex justify-around items-center">
            <div class="down pointer w-1/3" @click="points[index].origin_locations_open = !points[index].origin_locations_open">
              <svg
                  :class="{
                              'rotate-180': points[index].origin_locations_open
                          }"
                  width="8" height="5" viewBox="0 0 8 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M7 1L4 4L1 1" stroke="#1FBC2F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </div>
            <div class="flag pointer w-1/3" @click="saveLocation(point, index + ' drop', index)">
              <svg width="10" height="13" viewBox="0 0 10 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 2C1 1.44772 1.44772 1 2 1H8C8.55228 1 9 1.44772 9 2V10.3698C9 11.2893 7.86348 11.7214 7.25259 11.0342L5.74741 9.34084C5.34962 8.89332 4.65038 8.89332 4.25259 9.34084L2.74741 11.0342C2.13652 11.7214 1 11.2893 1 10.3698V2Z" stroke="#1FBC2F" stroke-width="2"/>
              </svg>
            </div>
            <div class="delete w-1/3" @click="deletePoint(index)">
              <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 1L7 7" stroke="#F2374D" stroke-width="2" stroke-linecap="round"/>
                <path d="M7 1L1 7" stroke="#F2374D" stroke-width="2" stroke-linecap="round"/>
              </svg>
            </div>
          </div>
          <div class="locations absolute p-2 bordered" v-if="points[index].origin_locations_open">
            <ul class="flex flex-col" v-for="(location, i) in locations" :key="i">
              <li class="pointer py-2" @click="selectLocation(location, index)">
                <span class='location-name'>{{location.name}}</span> - {{location.address}}
              </li>
            </ul>
          </div>
        </div>
        <div class="dropdown_block" v-if="point.showList">
          <ul class="search-dropdown flex flex-col gap-5 py-5">
            <li v-for="(option, i) in point.points" :key="i">
              <a href="javascript:void(0)" @click="updateDropOption(option, index)">
                {{ option.address }}
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import L from "leaflet";
import {infoMessage} from "../../../../admin/src/services/messages";
import CreateLocationModal from "@/components/CreateLocationModal.vue";
import {isValidPostcode} from "@/services/helpers";

export default {
  name: 'PlanLocations',
  components: {CreateLocationModal},
  data() {
    return {
      locations_names: {
        start: null,
        end: null
      },
      selected_location: null,
      selected_location_name: null,
      points: [{
        label: '',
        point: {
          address: null,
          position: null
        },
        points: null,
        showList: false,
        origin_locations_open: false,
        postcode: null,
        name: null
      },
        {
          label: '',
          point: {
            address: null,
            position: null
          },
          points: null,
          showList: false,
          origin_locations_open: false,
          postcode: null,
          name: null
        }],
      locations: [],
      markers: [],
    }
  },
  props: {
    cached_markers: {
      type: Array,
      required: false,
      default: () => []
    },
    selected_points_cloned: {
      type: Array,
      required: false,
      default: () => []
    },
    reset_all: {
      type: Boolean,
      default: false
    },
  },
  emits: ['push-markers', 'reverse-points'],
  methods: {
    addPoint() {
      if (this.points.length > 50) {
        return;
      }
      this.points.push({
        label: '',
        point: {
          address: null,
          position: null
        },
        points: null,
        showList: false,
        origin_locations_open: false,
        postcode: null
      });
    },
    reversePoints() {
      this.points = this.points.reverse();
      this.$emit('reverse-points')
    },
    deletePoint(index) {
      if(this.points.length <= 1) {
        return
      }
      this.markers.splice(index, 1);
      this.points.splice(index, 1);
      this.$emit('push-markers', this.markers)
    },
    async selectLocation(val, index) {
      this.locations_names = {
        start: null,
        end: null
      }
      this.points[index].origin_locations_open = false;
      this.points[index].point.address = val.name ? val.name + ', ' + val.address : val.address;
      this.points[index].point.position = {
        Latitude: val.geo.latitude,
        Longitude: val.geo.longitude
      }
      if (val.name) {
        this.points[index].name = val.name;
      }
      this.points[index].postcode = val.postcode
      if (this.markers[index]) {
        this.markers[index] = await L.latLng(val.geo.latitude, val.geo.longitude);
      } else {
        this.markers.push(L.latLng(val.geo.latitude, val.geo.longitude));
      }
      await this.$emit('push-markers', this.markers)


      if (this.points[0].name) {
        this.locations_names.start = this.points[0].name;
      }

      if (this.points[this.points.length - 1].name) {
        this.locations_names.end = this.points[this.points.length - 1].name;
      }
      await this.$emit('set-locations-names', this.locations_names)
    },
    saveLocation(val, name, index = 0) {
      if (!val.point.position) {
        infoMessage(
            'Oops. Something went wrong...','error'
        );
      }
      if (val.point && val.point.address) {
        this.selected_location = val.point;
        this.selected_location_name = name;
        this.selected_location.position = {
          Latitude: this.cached_markers[index].lat,
          Longitude: this.cached_markers[index].lng
        }
        this.$refs.create_location_modal.show = true;
      }
    },
    closeCreateLocationModal(val) {
      if (val) {
        infoMessage(
            `Location created`,
            this.getTopBanner ? 'success open_top_banner' : 'success'
        );
      } else {
        infoMessage(
            'Oops. Something went wrong...',
            this.getTopBanner ? 'error open_top_banner' :'error'
        );
      }
    },
    async updateDropOption(option, index) {
      this.points[index].point.address = option.address;
      this.points[index].point.position = option.position;
      this.points[index].showList = false;
      this.points[index].postcode = option.address.split(', ')[0];
      if (this.markers[index]) {
        this.markers[index] = await L.latLng(option.position.Latitude, option.position.Longitude);
      } else {
        this.markers.push(L.latLng(option.position.Latitude, option.position.Longitude));
      }

      this.$emit('push-markers', this.markers)
    },
    async searchAllPoints(index) {
      let dropOff = await this.searchPoints(this.points[index]?.point?.address) || null;
      this.points[index].points = dropOff;
      // this.points[index].showList = true
      if (this.points[index].points) {
        this.points[index].showList = true;
      } else {
        this.points[index].showList = false;
      }
    },
    async searchPoints(searchReq) {
      const isValidRearchReq = isValidPostcode(searchReq);
      if (!isValidRearchReq) {
        return;
      }
      const user = await JSON.parse(localStorage.getItem('user'));
      const request_data = {string: searchReq, user_id: user.id};
      const response = await this.$http.post(`${this.$http.apiUrl()}/subscriptions/search-points`, request_data).catch(err => {
        const errors = err.response?.data?.message;
        if (typeof errors === 'string') {
          return infoMessage(errors || 'Oops. Something went wrong...', 'error')
        }
        return this.errorsHandler(errors);
      });
      const result = [];
      if (!response?.data?.length) {
        return;
      }
      response?.data?.forEach((item) => {
        result.push({
          position: {
            Latitude: item.position.lat,
            Longitude: item.position.lng
          },
          address: item.address.label,
        })
      })
      return result;
    },
    async getLocations()  {
      try {
        const requestData = {
          skip: 0,
          take: 999,
          sort: 'name',
          column: 'name',
          search: '',
          ascending: false,
        }
        const response = await this.$http
            .getAuth(`${this.$http.apiUrl()}/locations?skip=${requestData.skip}&take=${requestData.take}&sort-by=${requestData.sort}&column=${requestData.column}&search=${requestData.search}&sort=${requestData.ascending}`);

        this.locations = response?.data?.locations?.data || [];

        if (this.$route.name === 'HereRePlan') {
          this.points = this.selected_points_cloned;
        }
      } catch (e) {
        console.log(e);
      }
    },

    async getPoints() {
      this.setAddVehicles(false);
      this.setAddTrailers(false);
      if (this.points[1].point.position == null || this.points[0].point.position == null) {
        return infoMessage('Please add route drops...', 'error')
      }

      let request_data = {
        pois: this.points,
        dates: this.pickerDates,
        v_size: this.calcFinalDimentions(),
        tolls: this.preferences.tolls,
        tunnels: this.preferences.tunnels,
        motorways: this.preferences.motorways
      }

      this.waypoints = [];
      this.setLoading(true)

      let result = await this.$http.postAuth(`${this.$http.apiUrl()}/subscriptions/directions`, request_data);
      let status = 0;
      let errMsg = null;
      if (result?.data[0]) {
        result.data.forEach(item => {
          if (item[0] && item[0].status && item[0].status != 0) {
            status = item[0].status;
            errMsg = item[0].detail[0].reason;
          }
          if (item.status && item.status != 0) {
            status = item.status;
            errMsg = item.detail[0].reason;
          }
        })
      }

      if (status > 0) {
        this.setLoading(false)
        return infoMessage(
            errMsg || 'Sorry, no compliant route found',
            'error'
        )
      }

      await this.clearMultiWaypoints()
      await this.clearMultiWaypointsText()
      for await (const item of result?.data) {
        await this.parseCoordinates(item)
      }

      this.timeDurationDistanceParse();
      this.direction_data = result.data;

      let thObj = this;
      this.points.forEach((item, index) => {
        let firstPart = item.point.address.split(', ');
        let secondPart = null;
        let secondRegion = null;
        let secondPostcode = null;
        let secondFullAddress = null;
        if (index + 1 < thObj.points.length) {
          secondPart = thObj.points[index + 1]?.point?.address?.split(', ') || null;
          secondRegion = secondPart[2] || null;
          secondPostcode = secondPart[0] || null;
          secondFullAddress = thObj.points[index + 1]?.point?.address;
        }
        thObj.partDistance.push({
          from:{
            region: firstPart[2] || null,
            postcode: firstPart[0] || null,
            address: item.point.address,
            admin_district: firstPart[2] || null
          },
          to:{
            region: secondRegion,
            postcode: secondPostcode,
            address: secondFullAddress,
            admin_district: secondRegion || null
          },
        })
      })

      this.setLoading(false)
      this.allowDrug = false;
    },
  },
  watch: {
    "cached_markers": {
      handler: function (val) {
        this.markers = val;
      },
      deep: true
    },
    "reset_all": {
      async handler(val) {
        if (val) {
          this.points = [{
            label: '',
            point: {
              address: null,
              position: null
            },
            points: null,
            showList: false,
            origin_locations_open: false,
            postcode: null,
            name: null
          },
            {
              label: '',
              point: {
                address: null,
                position: null
              },
              points: null,
              showList: false,
              origin_locations_open: false,
              postcode: null,
              name: null
            }];
          this.markers = [];
          this.locations_names = {
            start: null,
            end: null
          }
          this.$emit('push-markers', this.markers)
          this.$emit('set-locations-names', this.locations_names)
        }
      },
      deep: true
    }
  },
  mounted() {
    this.getLocations();
  }
}
</script>

<style lang="scss" scoped>
.vue2leaflet-map {
  height: 877px;
}
.location-name {
  font-weight: bold;
  font-size: 14px;
  color: #292929;
}
.add_items {
  position: absolute;
  top: 180px;
  left: 400px;
  min-height: 150px;
  z-index: 400;
  background-color: #fff;
  border-radius: 5px;
  &.r-side{
    left: calc(35% + 400px);
  }
}
.not-allow-banner {
  padding-top: 150px;
  margin-bottom: 200px;
}
.page {
  position: relative;
  .white-green {
    cursor: pointer;
  }
  .drivers {
    .white-green {
      padding: 12px;
    }
  }
  .inner {
    &.way {
      max-height: 650px;
      max-width: 350px;
      min-width: 320px;
      overflow-y: hidden;
    }
  }
  .vue-range-slider.slider-component {
    .slider {
      .slider-dot {
        background-color: #1fbc2f !important;
      }
    }
  }
  .vehicles {
    .actions {
      .white-green {
        text-align: center;
        margin: auto;
      }
    }
  }
  .vue-range-slider {
    width: 100%!important;
  }
  .alt_header {
    position: absolute;
    height: 50px;
    z-index: 401;
  }
  .outer {
    position: relative;

    border: 1px solid #D0D5DA;
    box-sizing: border-box;
    border-radius: 50px;
    width: 100%;
    .input_actions {
      position: absolute;
      right: 0;
      top: 10px;
      width: 60px;
    }
  }
  .plan_options {
    min-width: 355px;
    position: absolute;
    left: 15px;
    z-index: 401;
    border-radius: 6px;
    background-color: #ffffff;
    .actions {
      .white-green {
        padding-top: 0;
        padding-bottom: 0;
        min-height: 44px;
      }
    }
    &>.inner {
      padding: 15px;
      max-height: 600px;
      overflow: hidden;
    }
  }
  .position_plan_options {
    top: 90px;
  }
  .position_plan_options_plus {
    top: 90px;
  }
}
.btn_danger {
  padding: 7px 10px;
}
.input-group {
  input {
    max-width: 240px;
    background: white;
    padding-left: 5px;
    overflow: hidden;
    -o-text-overflow: ellipsis;
    -ms-text-overflow: ellipsis;
    text-overflow: ellipsis;
    border: none;
    background: transparent;
    outline: transparent;
  }
}
.plan_item {
  .expand {
    padding-right: 10px;
    .active {
      svg {
        transform: rotate(180deg);
      }
    }
  }
  .mb30 {
    .flex {
      width: 100%;
      .mr-2 {
        margin: 0;
        width: 100%;
      }
      .vue-daterange-picker {
        width: 100%;
      }
    }
  }
}
.actions {
  &.save {
    padding-top: 15px;
    button {
      min-width: 80px;
    }
  }
}
.preferences {
  input[type="radio"] {
    max-width: 20px;
    &:checked {
      &:checked {
        background: #FFA800 !important;
        border: #FFA800 !important;
      }
      &:before, &:after {
        background: #FFA800 !important;
        border: #FFA800 !important;
      }
    }
  }
}
.vue-daterange-picker ::v-deep .reportrange-text{
  border: 1px solid #D0D5DA;
  box-sizing: border-box;
  border-radius: 18px !important;
  width: 100%;
}
.data {
  padding-bottom: 20px;
  .icon {
    width: 20px;
  }
}
.icon {
  &.rotated {
    width: 44px;
    height: 44px;
    background: #fff;
    border-radius: 8px;
    left: 15px;
    position: absolute;
    top: 150px;
    z-index: 999;
  }
}
.add_name {
  position: absolute;
  max-width: 400px;
  left: 0;
  right: 0;
  top: 300px;
  margin: auto;
  z-index: 401;
  background: #fff;
}
.closeZone {
  transform: rotate(180deg);
  float: left;
}
.openZone {
  background: #fff;
  padding: 5px 5px;
  border-radius: 4px;
  width: 34px;
  border: 2px solid rgba(0,0,0,.2);
  background-clip: padding-box;
}
.custom-control {
  .controls {
    width: 230px;
    height: 150px;
    padding-left: 15px;
    background-color: #fff;
    img {
      max-width: 20px;
    }
  }
}
.no_visibility {
  opacity: 0;
}
.dropdown_block {
  width: 100%;
  padding: 0 10px;
  z-index: 405;
  background: #ffffff;
  border: 1px solid #D0D5DA;
  box-sizing: border-box;
  border-radius: 5px;
  position: absolute;
  top: 54px;
  left: 0;
}
.title {
  font-size: 14px;
}
.locations{
  &.absolute {
    background-color: #fff;
    width: 100%;
    z-index: 100;
  }
}
.down {
  .rotate {

  }
}
</style>