<template>
  <div class="flex add_name flex-col py-5 px-5" v-if="open_route_name_popup">
    <div class="top flex justify-between">
      <h2 class="text-left">Save route</h2>
      <span @click="open_route_name_popup = false" class="close pointer">x</span>
    </div>
    <div class="input_group flex flex-col">
      <label class="text-left">Route name</label>
      <input v-model="route_name" type="text">
    </div>
    <div class="actions py-3 flex w-2/3 m-auto gap-15">
      <button class="white-green w-1/2" @click="open_route_name_popup = false">Cancel</button>
      <button class="green-white w-1/2" @click="saveDirections">Save</button>
    </div>
  </div>
</template>
<script>
export default {
  name: 'SaveRoutePopup',
  props: {
    open_route_name_popup: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      route_name: ''
    }
  },
  methods: {
    saveDirections() {
      this.$emit('saveDirections', this.route_name)
    }
  }
}
</script>
<style scoped lang="scss">
.add_name {
  position: absolute;
  max-width: 400px;
  left: 0;
  right: 0;
  top: 300px;
  margin: auto;
  z-index: 401;
  background: #fff;
}
</style>