<template>
  <div class="drivers py-5 plan_item flex-col flex text-center">
    <div class="white-green py-3 title expand flex items-center justify-around pointer m-auto w-full flex items-center gap-10p" @click="show_drivers = ! show_drivers">
      <div class="flex items-center gap-10p">
        <span class='nowrap'>Assign driver</span>
        <span :class="{
              'active': show_drivers
              }">
                <svg width="8" height="5" viewBox="0 0 8 5" fill="none" xmlns="http://www.w3.org/2000/svg"><path data-v-2344f364="" d="M7 1L4 4L1 1" stroke="#1FBC2F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path></svg>
              </span>
      </div>
    </div>
    <div class="expanded py-5" v-if="show_drivers">
      <div v-if="!all_drivers.length" class="error pb-3">
        Ooops! No drivers.
      </div>
      <div v-else class="w-full px-4 pb-4">
        <div class="inline-block relative w-full">
          <CustomSelect
              :view="'name'"
              :array="all_drivers"
              v-on:selected-item-in-custom-select="selectDriverID($event)"/>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import CustomSelect from "@/components/customel/CustomSelect.vue";
import {infoMessage} from "../../../../admin/src/services/messages";
export default {
  name: 'PlanDrivers',
  components: {
    CustomSelect
  },
  props: {
    reset_all: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      show_drivers: false,
      all_drivers: [],
      forbid: false,
    }
  },
  methods: {
    async getAllDrivers() {
      const isForbid = await await this.$http.getAuth(`${this.$http.apiUrl()}/routes/forbid`);
      if (isForbid?.data?.forbid) {
        this.forbid = true;
        this.allowedCount = isForbid.data.count
      }
      const res_drivers = await this.$http.getAuth(`${this.$http.apiUrl()}/drivers/all`).catch(err => {
        console.error(err);
        this.all_drivers = [];
        return infoMessage(
            'Oops. Something went wrong...',
            this.getTopBanner ? 'error open_top_banner' :'error'
        );
      });

      this.all_drivers = res_drivers?.data?.drivers || [];
    },

    selectDriverID(val) {
      this.$emit('selectDriver', val.id)
    },
  },
  async mounted() {
    await this.getAllDrivers();
  },
  watch: {
    reset_all() {
      this.show_drivers = false;
    }
  }
}
</script>
<style scoped lang="scss">

</style>