<template>
  <div class="input-group plan_item time">
    <div class="mb30">
      <div class="flex ">
        <div class="mr-2 relative">
          <div class='toggle-btns flex w_100 py-4'>
            <div class='btn w_100 text-center p-2 pointer border'
                 :class='{active: is_departure}'
              @click='is_departure=true'
            >Departure date</div>
            <div class='btn w_100 text-center p-2 pointer border'
                 :class='{active: !is_departure}'
              @click='is_departure=false'
            >Arrival date</div>

          </div>
          <DateRangePicker
              class="select_date_range"
              :timePicker="true"
              :timePickerIncrement="10"
              :minDate="disabledDate"
              :singleDatePicker='"single"'
              @update='onApply'
              v-model="pickerDates">
            <template
                v-slot:input
                style="min-width: 350px;"
            >{{ start_date || end_date || defaultDate }}</template>
          </DateRangePicker>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import 'vue-range-component/dist/vue-range-slider.css'
import moment from 'moment';
export default {
  name: 'DateSelect',
  components: {
    DateRangePicker
  },
  props: {
    reset_all: {
      type: Boolean,
      default: false
    },
    route_line: {
      type: Object,
    },
  },
  data() {
    let startDate = new Date();
    let endDate = new Date();
    return {
      state :{
        disabledDates : {
          to: new Date(new Date().getTime() - 86400000)
        },
      },
      disabledDate : new Date().toISOString().slice(0, 10),
      is_departure: true,
      start_date: '',
      end_date: '',
      replan_start_date: '',
      replan_end_date: '',
      pickerDates: {
        startDate,
        endDate,
      },
    }
  },
  methods: {
    async updateDates() {
      await this.$emit('selectDates', {pickerDates: this.pickerDates, is_departure: this.is_departure})
    },
    roundPickerTenMinutes(date) {
      const newDate = new Date(date);
      newDate.setMinutes(Math.floor(newDate.getMinutes() / 10) * 10);
      return newDate;
    },
    async onApply(val) {
      this.pickerDates.startDate = val.startDate;
      this.pickerDates.endDate = val.endDate;

      const startMinutes = moment(val.startDate).minute();
      const endMinutes = moment(val.endDate).minute();
      if (startMinutes % 10 !== 0 || endMinutes % 10 !== 0) {
        this.pickerDates.startDate = this.roundPickerTenMinutes(val.startDate);
        this.pickerDates.endDate = this.roundPickerTenMinutes(val.endDate);
      }
      await this.$emit('selectDates', {pickerDates: this.pickerDates, is_departure: this.is_departure})
    },
    async handleClonedRouteDates() {
      if (this.route_line?.routes[0]) {
        this.replan_start_date = moment(this.route_line?.routes[0].sections[0].departure.time).format('DD-MM-YYYY, HH:mm');
        this.replan_end_date = moment(this.route_line?.routes[0].sections[this.route_line?.routes[0].sections.length -1].arrival.time)
            .format('DD-MM-YYYY, HH:mm');

        if (moment(this.replan_start_date, 'DD-MM-YYYY, HH:mm').isBefore(moment())) {
          this.replan_start_date = moment().format('DD-MM-YYYY, HH:mm');
        }
        if (moment(this.replan_end_date, 'DD-MM-YYYY, HH:mm').isBefore(moment())) {
          this.replan_end_date = moment().format('DD-MM-YYYY, HH:mm');
        }

        this.start_date = this.replan_start_date;
        this.end_date = this.replan_end_date;
      }
    }
  },
  async mounted() {
    if (this.$route.name === 'HereRePlan') {
      setTimeout(async () => {
        await this.handleClonedRouteDates();
      }, 1000)
    }
  },
  computed: {
    defaultDate() {
      return moment(new Date()).format('DD-MM-YYYY, HH:mm')
    }
  },
  watch: {
    "pickerDates" : {
      async handler(val) {
        if (!val) return;
        await this.updateDates();
        if (this.pickerDates.startDate) {
          this.start_date = moment(this.pickerDates.startDate).format('DD-MM-YYYY, HH:mm')
        }
        if (this.pickerDates.endDate) {
          this.end_date = moment(this.pickerDates.endDate).format('DD-MM-YYYY, HH:mm')
        }
      },
      deep: true
    },
    "is_departure" : {
      async handler() {
        await this.updateDates();
        // if (this.$route.name === 'HereRePlan') {
          // if (val) {
          //   this.start_date = this.replan_start_date;
          // } else {
          //   this.start_date = this.replan_end_date;
          // }
        // }
      },
      deep: true
    },
    reset_all() {
      this.is_departure = true;
      this.pickerDates = {
        startDate: new Date(),
        endDate: new Date(),
      }
    }
  }
}
</script>
<style scoped lang="scss">
.btn {
  transition: .2s;
  padding: 10px;
  &.active {
    color: #1FBC2F;
    background: #fff;
    font-weight: bold;
    border: 1px solid #1FBC2F;
    transition: .2s;
  }
}
</style>